import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Field } from "react-final-form";

import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

import TextField from "../fields/TextField";
import Select from "../fields/Select";

import moment from "moment";

import "moment/locale/es";

import { withTranslation, getLanguage } from "react-multi-lang";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

function AlertDialog(props) {
    const { close, buttons, title, content, open } = props;

    return (
        <Dialog open={open} onClose={close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    );
}

function InfoDialog(props) {
    const { close, buttons, infoOpen, infoTitle, arrivalInfo } = props;

    return (
        <Dialog open={infoOpen} onClose={close} aria-labelledby="info-dialog-title" aria-describedby="info-dialog-description">
            <DialogTitle id="info-dialog-title">{infoTitle}</DialogTitle>
            <DialogContent>
                {arrivalInfo.split("/n").map((line, index) => (
                    <DialogContentText id="info-dialog-description" key={index}>
                        {line}
                    </DialogContentText>
                ))}
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    );
}

const renderTimePicker = ({ input, label, calculateArrival }) => (
    <div className="picker">
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
                autoOk
                ampm={false}
                minutesStep={5}
                locale={moment.locale(getLanguage())}
                value={moment(input.value, "HH:mm")}
                onChange={e => {
                    input.onChange(e.format("HH:mm"));
                }}
                onAccept={e => {
                    calculateArrival(e);
                }}
                format="HH:mm"
                fullWidth={true}
                label={label}
            />
        </MuiPickersUtilsProvider>
    </div>
);

const IfNotCondition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value !== is ? children : null)}
    </Field>
);

class ArrivalForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            infoOpen: this.props.autoCheckin === "0" ? true : false,
            infoTitle: this.props.t("arrival.infoDialogTitle"),
            arrivalInfo: this.props.t("arrival.infoArrival"),
            title: "",
            content: ""
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleInfoClose = this.handleInfoClose.bind(this);
        this.calculateArrival = this.calculateArrival.bind(this);
    }

    handleOpen(title, content) {
        this.setState({ open: true, title: title, content: content });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleInfoClose() {
        this.setState({
            infoOpen: false
        });
    }

    calculateArrival(event) {
        let content = "";

        if (event.isAfter(moment({ hour: 0, minute: 0 })) && event.isBefore(moment({ hour: 11, minute: 0 }))) {
            content = this.props.t("arrival.ArrivalBefore11Information");
        } else if (event.isAfter(moment({ hour: 11, minute: 0 })) && event.isBefore(moment({ hour: 15, minute: 0 }))) {
            content = this.props.t("arrival.ArrivalBefore15Information");
        } else if (event.isAfter(moment({ hour: 21, minute: 0 }))) {
            content = this.props.t("arrival.ArrivalAfter21Information");
        } else {
            content = this.props.t("arrival.ArrivalNormalInformation");
        }

        this.handleOpen(this.props.t("arrival.ArrivalTimeTitle"), content);
    }

    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("arrival.Required"));

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("arrival.ArrivalDesc")}
                                {this.props.autoCheckin === "0" ? (
                                    <>
                                        <br /> <br /> {this.props.customerName}, {t("arrival.ArrivalMeeting")} 
                                    </>
                                ) : (
                                    <>
                                        <br /> <br /> {t("arrival.checkinTime")} 
                                    </>
                                )}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <Paper className={classes.paper}>
                            <Field name="arrivalTime" component={renderTimePicker} calculateArrival={this.calculateArrival} label={t("arrival.ArrivalRequestedTime")} validate={required}></Field>
                            <Typography variant="subtitle2" color="primary" paragraph>
                                {t("arrival.TakeIntoConsideration")}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <Paper className={classes.paper}>
                            <Field native name="travelArrivalBy" component={Select} label={t("arrival.ArrivalBy")} validate={required}>
                                <option value="" />
                                <option value={"Plane, Barcelona Airport (BCN)"}>{t("arrival.ArrivalPlaneBCN")}</option>
                                <option value={"Plane, Reus Airport (REU)"}>{t("arrival.ArrivalPlaneREU")}</option>
                                <option value={"Plane, Girona Airport (GRO)"}>{t("arrival.ArrivalPlaneGRO")}</option>
                                <option value={"Plane, Malaga Airport (AGP)"}>{t("arrival.ArrivalPlaneAGP")}</option>
                                <option value={"Train"}>{t("arrival.ArrivalTrain")}</option>
                                <option value={"Car"}>{t("arrival.ArrivalCar")}</option>
                                <option value={"Cruise"}>{t("arrival.ArrivalCruise")}</option>
                                <option value={"Plane, Seville Airport (SVQ)"}>{t("arrival.ArrivalPlaneSVQ")}</option>
                            </Field>
                        </Paper>
                    </Grid>

                    <IfNotCondition when="travelArrivalBy" is="Car">
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paper}>
                                <Field name="travelArrivalByMisc" component={TextField} label={t("arrival.ArrivalByExtra")} />
                            </Paper>
                        </Grid>
                    </IfNotCondition>
                </Grid>

                <div>
                    <AlertDialog
                        open={this.state.open}
                        title={this.state.title}
                        content={this.state.content}
                        close={this.handleClose}
                        buttons={
                            <Button onClick={this.handleClose} color="primary" autoFocus>
                                {t("arrival.Close")}
                            </Button>
                        }
                    />
                </div>

                <div>
                    <InfoDialog
                        infoOpen={this.state.infoOpen}
                        close={this.handleInfoClose}
                        infoTitle={this.state.infoTitle}
                        arrivalInfo={this.state.arrivalInfo}
                        buttons={
                            <Button onClick={this.handleInfoClose} color="primary" autoFocus>
                                {t("arrival.Close")}
                            </Button>
                        }
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(ArrivalForm));
